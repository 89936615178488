import {  Box, TextField, Typography, Grid, Button, Select, MenuItem, FormControl, InputLabel, } from '@mui/material';

import RegisterStyles from './Register.css';
import RegStepper from './RegStepper';


const Astro = () =>{

    const classes = RegisterStyles(); 

    const handleRegister = (event) =>{
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        console.log(data.get('firstName'));
    }

    const starOptions = ['Ashwini', 'Bharani', 'Krittika', 'Rohini', 'Mrighasira', 'Ardra', 'Punarvasu', 'Pushya', 'Ashlesha', 'Magha', 'Purva Phalguni', 'Uttara Phalguni', 'Hasta', 'Chitra', 'Swati', 'Vishaka', 'Anuradha', 'Jyestha', 'Moola', 'Purvashada', 'Uttarashada', 'Sharavan', 'Dhanishta', 'Shatabisha', 'Purvabhadra', 'Uttarabhadra', 'Revati'];
    const raasiOptions = ['Mesha (Aries)',  'Vrishabha (Taurus)', 'Mithuna (Gemini)', 'Karka (Cancer)', 'Simha (Leo)',
                        'Kanya (Virgo)', 'Tula (Libra)', 'Vrishchika (Scorpio)', 'Dhanu (Sagittarius)', 
                        'Makara (Capricorn)', 'Kumbha (Aquarius)','Meena (Pisces)',];
    let hoursOpts = [];
    for(let i=0; i<12; i++){
        hoursOpts.push( i<10 ? `0${i}`  : i);
    }
    let minutesOpts = [];
    for(let i=0; i<12; i++){
        minutesOpts.push( i<10 ? `0${i}`  : i);
    }

    return (
        <Box sx={{ display: `flex`, flexDirection:`column`, alignItems: `center`,marginTop: 8, width:`100%`}} >
            <Typography component="h1" variant='h5' mb={3} className={classes.center}>
                Personal details will fetch better matching results
            </Typography>
            <RegStepper activeStep={2} />
            
            <Box component="form" onSubmit={handleRegister} sx={{ mt:2 }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} >
                        <FormControl fullWidth > 
                        <InputLabel >Star</InputLabel>
                        <Select value="" label="Star" name="email" fullWidth required sx={{background:`#FFF`}} >
                                <MenuItem value="">Star</MenuItem>
                                {starOptions.map((option)=>(
                                <MenuItem value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>    
                    <Grid item xs={12} sm={12} >
                        <FormControl fullWidth > 
                        <InputLabel >Raasi</InputLabel>
                        <Select value="" label="Raasi" name="email" fullWidth required sx={{background:`#FFF`}} >
                            <MenuItem value="">Raasi</MenuItem>
                            {raasiOptions.map((option)=>(
                                <MenuItem value={option}>{option}</MenuItem>
                            ))}        
                        </Select>
                        </FormControl>
                    </Grid>    
                    <Grid item xs={12} sm={12} >
                        <TextField type="text" label="Gothra" name="Name" fullWidth  />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <TextField type="text" label="Date of Birth" name="Name" fullWidth  />
                    </Grid>
                    <Grid item xs={4} >
                        <Select value="00" label="Hours" name="email"  fullWidth required sx={{background:`#FFF`}} >
                            {hoursOpts.map((hour)=>(
                                <MenuItem value={hour}>{hour}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={4} >
                    <Select value="00" label="Minutes" name="email" fullWidth required sx={{background:`#FFF`}} >
                            {minutesOpts.map((minute)=>(
                                <MenuItem value={minute}>{minute}</MenuItem>
                            ))}                            
                        </Select>
                    </Grid>
                    <Grid item xs={4} >
                        <Select value="am" label="" name="email" fullWidth required sx={{background:`#FFF`}} >
                            <MenuItem value="am">AM</MenuItem>
                            <MenuItem value="pm">PM</MenuItem>
                        </Select>
                    </Grid>
                </Grid>                
                <Grid container mt={3}>
                    <Grid item xs={6}>
                         <Button variant="contained" color="secondary">Previous</Button> 
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: `right`}}>
                        <Button variant="contained" type="submit"  sx={{ mt:2 }}>Next</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        )
}

export default Astro;