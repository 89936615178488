
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, TextField, Button, Container, Select, MenuItem, FormControl, InputLabel } from "@mui/material";


const Signup = () => {

    const navigate = useNavigate();

    const handleRegister = () => {

    }

    return (
            <Box sx={{ background: `url('https://www.weddingbazaar.com/images/heroBannerImageDesktop2xCompressed.png') no-repeat center -80px;background-size: cover;"`, minHeight:`500px` }} >
            <Container sx={{ position: `relative`, minHeight: `500px`}}>
            <Box p={3} sx={{background: `#ffcf71`, position:`absolute`, bottom: `0px`, left: `0px`, width:`100%` }}>
            <Typography component="h1" variant='h5'>Meet your soulmate here!</Typography>
            <Box component="form" onSubmit={handleRegister} sx={{ mt:2 }} >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3} >
                     <TextField type="text" label="First Name" name="firstName" fullWidth required sx={{background:`#FFF`}} />
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <FormControl fullWidth > 
                        <InputLabel >Profile Create for</InputLabel>
                        <Select value="" label="Profile Create for" name="email" fullWidth required sx={{background:`#FFF`}} >
                            <MenuItem value="">Profile Create for</MenuItem>
                            <MenuItem  value="1">Self</MenuItem>
                            <MenuItem  value="2">Son</MenuItem>
                            <MenuItem  value="3">Daughter</MenuItem >
                            <MenuItem  value="4">Brother</MenuItem >
                            <MenuItem  value="5">Sister</MenuItem >
                            <MenuItem  value="6">Friend</MenuItem >
                            <MenuItem  value="8">Relative</MenuItem >
                        </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <TextField type="text" label="Phone Number" name="password" fullWidth required sx={{background:`#FFF`}} />
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <Grid mt={1}>
                            <Button size="large" variant="contained" type="submit" fullWidth >Register Free</Button>
                        </Grid>
                        <Typography component="p" variant='p' mt={2}>
                            Already Member <Button variant='text' onClick={ () => navigate("/login") }>Login</Button> Here!
                        </Typography>
                    </Grid>
                </Grid>                
            </Box>
            </Box>
            </Container>   
            </Box>
    )
}

export default Signup;