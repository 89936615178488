import { useState } from "react";
import { Box, Container,  Typography,Button, Grid } from  "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";


import homeStyles from "./Home.css";

const steps = [
    {
      label: 'San Francisco – Oakland Bay Bridge, United States',
      imgPath:
        'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
      label: 'Bird',
      imgPath:
        'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
      label: 'Bali, Indonesia',
      imgPath:
        'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    },
    {
      label: 'Goč, Serbia',
      imgPath:
        'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'Goč, Serbia',
        imgPath:
          'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
        label: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
          'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
      },
      {
        label: 'Bird',
        imgPath:
          'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
      },
      {
        label: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
          'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
      },
      {
        label: 'Bird',
        imgPath:
          'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
      },
];    

const Stories = () => {
        const classes = homeStyles(); 
        const [activeSteps, setactiveSteps] = useState([0,1,2,3]);
        const maxSteps = steps.length;

        const handleNext = () => {
            setactiveSteps((prevactiveSteps) => {
                let temp = [];
                let current = 0;
                prevactiveSteps.forEach(element => {
                    current = steps[element+1] ? element+1 : 0;
                    temp.push(current);
                });
                return temp;
            });
        };

        const handleBack = () => {
            setactiveSteps((prevactiveSteps) => {
                let temp = [];
                let current = 0;
                prevactiveSteps.forEach(element => {
                    current = steps[element-1] ? element-1 : maxSteps-1;
                    temp.push(current);
                });
                return temp;
            });
        };

        return (
            <Box>
            <Container>
            <Box sx={{ flexGrow: 1 }}>
                <Typography component="p" variant="h4" p={5} className={classes.center}>Stories</Typography>
                <Grid container item xs={12} >
                    {activeSteps.map((activeStep) => (
                        <Grid item key={`stories-${activeStep}`}  lg={3}>
                            <Typography component="img" src={steps[activeStep].imgPath} sx={{ width:"100%", padding:`5px`}}></Typography>
                            <Typography component="div"> {steps[activeStep].label}</Typography>
                        </Grid>
                    ))}                    
                </Grid>
            <Grid container item xs={12}>
                <Grid item xs={6} sx={{textAlign:`left`}}>
                    <Button size="small" onClick={handleBack}>
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} sx={{textAlign:`right`}}>
                    <Button
                        size="small"
                        onClick={handleNext}
                    >Next
                    <KeyboardArrowRight />   
                    </Button>
                </Grid>
                
            </Grid>
            </Box>            
            </Container>
        </Box>
    )
}
  
export default Stories;
