import { Box, Card, CardContent, Container, Grid, Typography } from  "@mui/material";

import homeStyles from "./Home.css";

const WhyWeNeed = () => {
    const styles = homeStyles(); 

    return (
        <Box >
        <Container>
            <Box sx={{ textAlign: `center`}}>
                <Typography component="p" variant="h4" pt={5}>Why Elite Matrimony?</Typography>
                <Typography component="p" variant="h6" pt={2}>
                    Elite Matrimony, a part of matrimony.com group, is a pioneer and leader in online matrimony services for Indians worldwide.
                </Typography>
                <Typography component="p" variant="h6 pt={2}">
                    We bring over 15 years of expertise in pioneering the Elite matchmaking service, and offer the most exclusive database of truly Elite matches for you!
                </Typography>
            </Box> 

            <Grid container item  pt={5} spacing={2}>
                <Grid item xs={6} lg={3} >
                    <Card className={styles.yellowCard}>
                    <CardContent className={styles.cardCenter}>
                        <Typography component="p" variant="h4">15+</Typography>
                        <Typography component="p" variant="h6">Years of expertise</Typography>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={3} >
                    <Card className={styles.yellowCard}>
                    <CardContent className={styles.cardCenter}>
                            <Typography component="p" variant="h4">10,000+</Typography>
                            <Typography component="p" variant="h6">Elite customers serviced</Typography> 
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={3} >
                    <Card className={styles.yellowCard}>
                        <CardContent className={styles.cardCenter}>
                            <Typography component="p" variant="h4">100+</Typography>
                            <Typography component="p" variant="h6">Relationship Managers</Typography> 
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={3} >
                    <Card className={styles.yellowCard}>
                        <CardContent className={styles.cardCenter}>
                            <Typography component="p" variant="h4" >100%</Typography>
                            <Typography component="p" variant="h6">Confidential</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
        </Box>
    )
}

export default WhyWeNeed;