import {  Box, Card, CardMedia, CardContent, Container, Grid, Typography } from  "@mui/material";

import homeStyles from "./Home.css";

const HowItWorks = () => {
    const classes = homeStyles(); 

    return (
        <Box >
        <Container>
            <Box className={classes.center}>
                <Typography component="p" variant="h4" pt={5}>Why Elite Matrimony?</Typography>
            </Box>
            <Grid container spacing={2} pt={5}>
                <Grid item xs={12} lg={4} >
                    <Card>
                        <CardMedia  component="img" height="240" image="/images/support.png"  alt="Understanding Your Preferences"/>
                        <CardContent sx={{height: `200px`, overflow:`hidden`}}>
                        <Typography gutterBottom variant="h5" component="div">
                            Understanding Your Preferences
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            A dedicated Relationship Manager from your region will thoroughly understand your preferences, lifestyle and cultural nuances that will help in finding the best possible matches for you.
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={4} >
                    <Card>
                        <CardMedia  component="img" height="240" image="/images/matching1.png"  alt="Recommending Matches"/>
                        <CardContent sx={{height: `200px`, overflow:`hidden`}}>
                        <Typography gutterBottom variant="h5" component="div">
                        Recommending Matches
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                                Your Relationship Manager will recommend matches by consistently searching and shortlisting profiles based on your preferences.
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <Card>
                        <CardMedia  component="img" height="240"  image="/images/matching.png"  alt="Connecting with Prospects"/>
                        <CardContent sx={{height: `200px`, overflow:`hidden`}}>
                        <Typography gutterBottom variant="h5" component="div">
                                Connecting with Prospects
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            After exploring mutual interests, your Relationship Manager will schedule meetings with your matches and families, saving your time and helping you find the right partner.
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
        </Box>
    )
}

export default HowItWorks;