import { Outlet, useNavigate } from "react-router-dom";
import { Box, AppBar, Button, Toolbar, Typography, Drawer, Link } from "@mui/material"
import { DragHandleRounded, PhoneAndroidOutlined } from "@mui/icons-material"
import { useState } from "react";


const Header = () => {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const pages = ["home","search", "login", "register"];

    const navigatePage = (page) => {
        toggleDrawer(false);
        
        switch(page){
            case 'login':
                navigate('/login');
                break;
            case 'register':
                navigate('/register');
                break;
            case 'search':
                navigate('/search');
                break;
            default:
                navigate('/');
        }
    }

    const toggleDrawer = (open) => {
        setOpenMenu(open);
    }

    return (
        <>
        <AppBar postion="static" sx={{background: `#861932`}}>
            <Toolbar>
                <Box sx={{ display:'flex', justifyContent:'flex-start' }} >
                    <Typography component="a" variant="h4" href="/" sx={{ color:`#FFF`, textDecoration:`none`}}>
                        <img src="/images/logo.png" height="60" alt="We Matrimony" />
                    </Typography>
                </Box>
                <Box  sx={{ display: {xs: 'none', md: 'flex'}}}>
                    {pages.map((page) =>(
                        <Button key={page} sx={{ color: `#fff` }} onClick={() => navigatePage(page) }>
                            {page}
                        </Button>
                    ))}
                    <Link sx={{color:"#FFFFFF"}}><PhoneAndroidOutlined /> +91 3938313838</Link>
                </Box>
                

                <Box  sx={{ display: {sm: '', md: 'none',}, position: 'fixed', right:'5px'}}>
                    <Box>
                        <Button sx={{ border: '1px solid #fff'}} onClick={() =>toggleDrawer(true)} 
                        color="secondary"
                        aria-label = "menu"
                        >
                            <DragHandleRounded sx={{ color: '#fff'}} />
                        </Button>
                        <Drawer anchor="right" open={openMenu} onClose={()=>toggleDrawer(false)}>
                            {pages.map((page) =>(
                                <Button key={page}  onClick={() => navigatePage(page) }>
                                    {page}
                                </Button>
                            ))}
                        </Drawer>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
        <Outlet />
        </>
    )

}

export default Header;