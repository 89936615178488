import React from 'react'
import {  Box, Typography } from "@mui/material";
import Signup from '../components/Home/Signup';
import WhyWeNeed from '../components/Home/WhyWeNeed';
import HowItWorks from '../components/Home/HowItWorks';
import Stories from '../components/Home/Stories';

function Home() {
  return (
    <Box maxWidth="xs">
      <Signup />
      <WhyWeNeed />
      <HowItWorks />
      <Stories />
      <Typography component="p" mt={5}></Typography>
    </Box>
  )
}


export  default Home