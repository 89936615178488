import { LoginOutlined } from '@mui/icons-material';
import { Avatar, Box, TextField, Typography, Grid, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HowItWorks from '../components/Home/HowItWorks';

export  default function Login () {
    const navigate  = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });

    }

    return (
        <Container>
            <Grid container xs={12} lg={12} sx={{ marginTop:`100px` }}>
                <Grid container xs={12} lg={6} sx={{ display: {xs: 'none', md: 'flex'}}} >
                    <Typography component="img" src="images/matching.png" width="100%"></Typography>
                </Grid>
                <Grid container xs={12} lg={6}>
                    <Box sx={{ display: `flex`, flexDirection:`column`, alignItems: `center`,marginTop: 8, width:`100%`}} >
                    <Avatar sx={{ m:1 , bgcolor: 'secondary.main'}}>
                        <LoginOutlined />
                    </Avatar>
                    <Typography component="h1" variant='h5'>
                            Sign In                                
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={6} sx={{ mt:1 }}>
                                <TextField  name="email" type="email" fullWidth autoFocus label="User Email" required />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt:1 }}>
                                <TextField name="password" type="password" fullWidth label="Password" required />
                            </Grid>
                        </Grid>
                        <Button sx={{ mt:1 }} type='submit' fullWidth variant="contained" >Login</Button>
                    </Box>                 
                    <Box>
                        <Button variant='text' onClick={ ()=> navigate('/forgot-password')}>Forgot Password</Button>
                        <Button variant='text' onClick={ ()=> navigate('/register')}>Register</Button>
                    </Box>
                    </Box>
                </Grid>
            </Grid>
        <HowItWorks />
        </Container>
            
    )

}
