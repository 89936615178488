import { Grid, MenuItem, TextField, Select, InputLabel, Typography, Button} from "@mui/material";

const SearchForm = () => {

    const ages = [];
    for(let i=20; i<60; i++){
        ages.push(i);
    }

    return (
        <Grid container xs={12} spacing={2}>
            <Typography component="h1" variant="h5" m={2}>Search Filter</Typography>
            <Grid item xs={12}  >
                <TextField type="text" label="Name" name="Name" fullWidth required />
            </Grid>
            <Grid item xs={12}  >
                <InputLabel>Age</InputLabel>
                <Select value="20" label="Age From" name="ageFrom" sx={{width:`50%`}} >
                    {ages.map((age) => (
                        <MenuItem value={age}>{age}</MenuItem>
                    ))}
                </Select>
                <Select  value="60" label="Age To" name="ageTo" sx={{width:`50%`}}>
                    {ages.map((age) => (
                        <MenuItem value={age}>{age}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}  >
                        <InputLabel >Education</InputLabel>
                        <Select value="" label="Education" name="email" fullWidth sx={{background:`#FFF`}} >
                                <MenuItem value="">Education</MenuItem>
                                <MenuItem  value="1">10th</MenuItem>
                                <MenuItem  value="2">12th</MenuItem>
                                <MenuItem  value="3">BA</MenuItem >
                                <MenuItem  value="4">Bsc</MenuItem >
                                <MenuItem  value="5">BE</MenuItem >
                                <MenuItem  value="6">MBBS</MenuItem >
                                <MenuItem  value="8">Others</MenuItem >
                            </Select>
            </Grid>
            <Grid item xs={12}  >
                <TextField type="text" label="Income" name="Name" fullWidth required />
            </Grid>
            <Grid item xs={12}  mt={2}>
                <Button variant="contained" color="secondary" fullWidth >Search</Button>
            </Grid>     
        </Grid>
    )
}

export default SearchForm;