
import { Box, TextField, Typography, Grid, Button, Select, MenuItem, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio, FormLabel 
       } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RegisterStyles from './Register.css';
import RegStepper from './RegStepper';

const Personality = () =>{

    const classes = RegisterStyles(); 
    const navigate = useNavigate();

    const handleRegister = (event) =>{
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        console.log(data.get('education'));
        navigate('/register/step3');
    }

    const heightOptions = [];
    for(let i=4; i<8; i++){
        for(let j=0; j<12; j++){
            let inches =  j >0 ? `${j} inches` : ''; 
            let option = {value: i+``+j , label:  `${i} feet ${inches}` };
            heightOptions.push(option);
        }
    }

    return (
        <Box sx={{ display: `flex`, flexDirection:`column`, alignItems: `center`,marginTop: 8, width:`100%`}} >
            <Typography component="h1" variant='h5' mb={3}>
                Personal details will fetch better matching results
            </Typography>
            <RegStepper  activeStep={1} />
            
            <Box component="form" onSubmit={handleRegister} sx={{ mt:2 }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <FormControl fullWidth > 
                        <InputLabel >Education</InputLabel>
                        <Select value="" label="Education" name="email" fullWidth sx={{background:`#FFF`}} >
                                <MenuItem value="">Education</MenuItem>
                                <MenuItem  value="1">10th</MenuItem>
                                <MenuItem  value="2">12th</MenuItem>
                                <MenuItem  value="3">BA</MenuItem >
                                <MenuItem  value="4">Bsc</MenuItem >
                                <MenuItem  value="5">BE</MenuItem >
                                <MenuItem  value="6">MBBS</MenuItem >
                                <MenuItem  value="8">Others</MenuItem >
                            </Select>
                        </FormControl>
                    </Grid>    
                    <Grid item xs={12} sm={6} >
                        <TextField type="text" label="Specific Education" name="Name" fullWidth  />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                    <FormControl fullWidth > 
                    <InputLabel >Height</InputLabel>
                    <Select value="" label="Profile Create for" name="email" fullWidth sx={{background:`#FFF`}} >
                            <MenuItem value="">Height</MenuItem>
                            {heightOptions.map((option)=>(
                                <MenuItem value={option.value}>{option.label}</MenuItem>
                            ))}
                            
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                          <FormLabel>Marital Status</FormLabel>
                          <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="MaritalStatus"
                                defaultValue="end"
                            >
                                <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Unmarried"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Widower"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Divorced"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                
                                <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Separated"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                        </RadioGroup>
                    </Grid>

                    
                    <Grid item xs={12} sm={12} >
                         <FormLabel>Employed In</FormLabel>
                          <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="employedIn"
                                defaultValue="end"
                            >
                                <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Government"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Defence"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Private"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Business"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                
                                <FormControlLabel
                                value="4"
                                control={<Radio />}
                                label="Self Employed"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="5"
                                control={<Radio />}
                                label="Others"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                            </RadioGroup>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} >
                          <FormLabel>Family Status</FormLabel>
                          <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                defaultValue="end"
                            >
                                <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Middle class"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Upper middle class"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Rich / Affluent"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                          <FormLabel>Family Type</FormLabel>
                          <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="familyType"
                                defaultValue="end"
                            >
                                <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Joint family"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Nuclear family"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                            </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                         <FormLabel>Physical Status</FormLabel>
                          <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="PhysicalStatus"
                                defaultValue="end"
                            >
                                <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Normal"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                                <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Physically Challenged"
                                labelPlacement="end"
                                className={classes.radioOutline}
                                />
                            </RadioGroup>
                    </Grid>
                </Grid>
                
                <Grid container mt={3}>
                    <Grid item xs={6}>
                         <Button variant="contained" color="secondary">Previous</Button> 
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: `right`}}>
                        <Button variant="contained" type="submit"  sx={{ mt:2 }}>Next</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        )
}

export default Personality;