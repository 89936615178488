import { EmailOutlined, PhoneAndroidOutlined } from "@mui/icons-material";
import { Box, Grid, List, ListItem, ListItemIcon, Link, Container } from "@mui/material";
import {createUseStyles} from 'react-jss'


const footerStyles = createUseStyles((theme) => ({
    footer: {
        background: `#861932`,
    },
    link:{
        color: `#FFFFFF`,
        textDecoration: `none`,
        '&:hover':{
            textDecoration: `underline`,
            cursor: `pointer`,
        }
    },
    copyright:{
        background: `#000000`, 
        color:'#FFF',
        fontStyle: `italic`,
        fontSize: `13px`,
    }
  }));


const Footer = () => {

    const classes = footerStyles();

    return (
            <Box mt={5}  className={classes.footer}>
            <Container>
            <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                    <List dense={true}>
                        <ListItem><Link className={classes.link}  >Home</Link></ListItem>
                        <ListItem><Link className={classes.link} >About Us</Link></ListItem>
                        <ListItem><Link className={classes.link} >Terms and Conditions</Link></ListItem>
                        <ListItem><Link className={classes.link} >Privacy & Policy</Link></ListItem>
                        <ListItem><Link className={classes.link} >Safe Matrimony</Link></ListItem>
                    </List>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <List dense={true}>
                        <ListItem><Link className={classes.link} >Join Now</Link></ListItem>
                        <ListItem><Link className={classes.link} >Success Stories</Link></ListItem>
                        <ListItem><Link className={classes.link} >Packages</Link></ListItem>
                    </List>
                </Grid>
                <Grid item lg={4} xs={12} mb={2}>
                    <List dense={true}>
                        <ListItem><ListItemIcon sx={{color:"#FFFFFF"}}><EmailOutlined /></ListItemIcon><Link className={classes.link} >Email</Link></ListItem>
                        <ListItem><ListItemIcon sx={{color:"#FFFFFF"}}><PhoneAndroidOutlined /></ListItemIcon><Link className={classes.link} >Phone</Link></ListItem>
                        <ListItem>
                            <img src="/images/logo.png" height="100" alt="We Matrimony"/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            </Container>
            <Grid container p={2} spacing={2} className={classes.copyright}>
                <Grid item xs={6} >
                    Copyright &copy; 2024 All rights reserved
                </Grid>
                <Grid item xs={6} sx={{ textAlign: `right`}}>
                    Developed by <Link className={classes.link} >Mugitechnologies.com</Link>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Footer;