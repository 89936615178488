
const myTheme = {
  palette: {
    primary: {
      light: '#e2f3fa',
      main: '#6ec1e4',
      dark: '#3691b2',
      shade: 'hsl(198, 71%, 90%)',
    },
    secondary: {
      light: '#ffc749',
      main: '#fc9607',
      dark: '#c36700',
    },
    tertiary: {
      light: '#78f65d',
      main: '#3cc229',
      dark: '#009000',
    },
    background: {
      default: `#fafafa`,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
  typography: {
    fontFamily: ["'Source Sans Pro'", 'sans-serif'].join(','),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '16px',
          textTransform: 'none',
        },
        outlined: {
          textTransform: 'none',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: `none`,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontWeight: 600,
          letterSpacing: '-1px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: false,
        variant: 'outlined',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAccordion: {
      defaultProps: {
        square: false,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          margin: '8px 0',
          overflow: 'hidden',

          '&::before': {
            display: 'none',
          },
        },
        rounded: {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderRadius: '16px',

          '&:first-of-type': {
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderRadius: '16px',
          },

          '&:last-child': {
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            borderRadius: '16px',
          },
        },
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          flex: `1 1 auto`,
        },
        input: {
          marginRight: `16px`,
        },
      },
    },
  },
  global: {
    links: {
      cursor: 'pointer',
    },
    title: {
      fontWeight: 900,
    },
    circProgress: {
      marginLeft: '8px',
    },
    paper: {
      padding: '24px',
    },
    module: {
      marginTop: '16px',
    },
    cardHeader: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #f0f0f0',
      padding: '12px 16px',
    },
    container: {
      margingTop: '36px',
    },
    spacer: {
      flex: '1 1 auto',
    },
    loadingInline: {
      position: 'absolute',
      left: '50%',
      transform: 'translate3d(-50%, 0, 0)',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    backdrop: {
      zIndex: 999,
      background: `rgba(255,255,255,0.9)`,
    },
  },
  colors: {
    lowestRank: `#e33d3d`,
    lowRank: `#e86a61`,
    medRank: `#f1f52f`,
    highRank: `#7ae46e`,
  },
  center: {
    textAlign: `center !important`
  }
};

export default myTheme;
