import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import { createContext } from 'react';

import Home from "./pages/Home"
import Search from "./pages/Search"
import Memo from "./pages/Memo"
import Login from "./pages/Login"
import Register from "./pages/Register"
import ForgotPassword from "./pages/ForgotPassword"
import Header from './components/Header';
import Footer from './components/Footer';

import {createTheming} from 'react-jss'
import myTheme from "./App.css";

const ThemeContext = createContext({});
const theming = createTheming(ThemeContext);
const {ThemeProvider} = theming;


function App() {

  return (
    
    <ThemeProvider theme={myTheme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/"  element={<Header />} >
                <Route index  element={<Home />} />
                <Route path="memo"  element={<Memo />} />
                <Route path="search"  element={<Search />} />
                <Route path="login"  element={<Login />} />
                <Route path="register/*"  element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
              </Route>  
            </Routes>
            <Footer />
          </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
