import { memo } from "react";
import { List } from "@mui/material";

function  ToDoList ({todos}) {

    return (
        <>
        {todos.map((todo, index) => {
            return (<List key={index}>{todo}</List>)
        })}
        </>
    )


}

export default memo(ToDoList);