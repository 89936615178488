
import { Typography, Grid, Container, } from '@mui/material';
import HowItWorks from '../components/Home/HowItWorks';
import { Route, Routes } from 'react-router-dom';
import Basic from '../components/Register/Basic';
import Personality from '../components/Register/Personality';
import Astro from '../components/Register/Astro';

const Register = () => {

    return (
        <Container>
            <Grid container xs={12} lg={12} sx={{ marginTop:`75px` }}>
                <Grid item  xs={12} lg={6} pr={2} sx={{ display: {xs: 'none', md: 'flex'}}}>
                    <Typography component="img" src={process.env.PUBLIC_URL + '/images/matching.png'} width="100%"></Typography>
                </Grid>
            <Grid item xs={12} lg={6}>
                        <Routes>
                        <Route index path={`/`} element={<Basic />} />
                        <Route index path={`step1`} element={<Basic />} />
                        <Route index path={`step3`} element={<Astro />} />
                        <Route index path={`step2`} element={<Personality />} />
                        {/*
                        <Route path={`letter/add`} element={< />} />
                        https://www.srivishwakarmasangam.org/register.html
                        */}
                        </Routes>            
        </Grid>
        </Grid>
        <HowItWorks />
        </Container>
    )
}
export default Register;