
import { Box, Stepper, Step, StepLabel } from "@mui/material";


const RegStepper = ({activeStep}) => {
    const steps = ['Contact Info', 'Lifestyle & family', 'Astrology Info'];

    return (
        <Box mb={2} sx={{width: `100%`}}>
            <Stepper alternativeLabel activeStep={activeStep ?? 0 } >
            {steps.map((label, index) => (
                <Step key={label} >
                    <StepLabel color="inherit" >
                    {label}
                    </StepLabel>
                </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default RegStepper;