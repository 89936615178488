
import { LoginRounded } from '@mui/icons-material';
import { Avatar, Box, TextField, Typography, Grid, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RegStepper from './RegStepper'


const  Basic = () =>{

    const navigate = useNavigate();

    const handleRegister = (event) =>{
        event.preventDefault();
        let data = new FormData(event.currentTarget);
        console.log(data.get('firstName'));
        navigate('/register/step2');
    }



    return (
        <Box sx={{ display: `flex`, flexDirection:`column`, alignItems: `center`,width:`100%`}} >
            <Avatar sx={{ bgcolor: `primary.main`}}>
                <LoginRounded />
            </Avatar>
            <Typography component="h1" variant='h5' mb={2}>Register</Typography>

            <RegStepper  activeStep={0} />
            <Box component="form" onSubmit={handleRegister} sx={{ mt:2 }} >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} >
                        <TextField type="text" label="Name" name="Name" fullWidth required />
                    </Grid> 
                    <Grid item xs={12} sm={6} >
                    <FormControl fullWidth > 
                    <InputLabel>Gender</InputLabel>
                        <Select value="" label="Gender" name="email" fullWidth  sx={{background:`#FFF`}} >
                            <MenuItem value="">Gender</MenuItem>
                            <MenuItem  value="1">Male -Looking BRIDE</MenuItem>
                            <MenuItem  value="2">Female -Looking GROOM</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                    <FormControl fullWidth > 
                    <InputLabel >Profile Create for</InputLabel>
                        <Select value="" label="Profile Create for" name="email" fullWidth  sx={{background:`#FFF`}} >
                            <MenuItem value="">Profile Create for</MenuItem>
                            <MenuItem  value="1">Self</MenuItem>
                            <MenuItem  value="2">Son</MenuItem>
                            <MenuItem  value="3">Daughter</MenuItem >
                            <MenuItem  value="4">Brother</MenuItem >
                            <MenuItem  value="5">Sister</MenuItem >
                            <MenuItem  value="6">Friend</MenuItem >
                            <MenuItem  value="8">Relative</MenuItem >
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextField type="text" label="Phone" name="Name" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextField type="email" label="Email" name="email" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextField type="password" label="Password" name="password" fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextField type="password" label="Confirm Password" name="confirmPassword" fullWidth required />
                    </Grid>
                </Grid>
                <Button variant="contained" type="submit" fullWidth sx={{ mt:2 }}>Register</Button>
                <Grid container >
                    <Grid item xs>Already Member <Button variant='text' onClick={ () => navigate("/login") }>Login</Button> Here!</Grid>
                </Grid>
            </Box>
        </Box>
        )
}

export default Basic;