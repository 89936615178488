import {createUseStyles} from 'react-jss'


const homeStyles = createUseStyles((theme) => ({
    yellowCard: {
        boxShadow: `0px 2px 1px -1px rgba(247, 165, 12,0.2),0px 1px 1px 0px rgba(247, 165, 12, 1),0px 1px 3px 0px rgba(247, 165, 12,1)`,
        minHeight: `200px`,
        textAlign: `center`,
    },
    cardCenter:{
        paddingTop: `60px`,
    },
    label: {
      fontWeight: 'bold'
    }
  }));

export default homeStyles;