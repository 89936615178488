import { LoginRounded } from "@mui/icons-material";
import { Avatar, Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import HowItWorks from "../components/Home/HowItWorks";


const ForgotPassword = () => {

    const handleSubmit = (event) =>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data.get('email'));

    }
    return (
        
        <Container>
            <Grid container xs={12} lg={12} sx={{ marginTop:`100px` }}>
                <Grid container xs={12} lg={6} sx={{ display: {xs: 'none', md: 'flex'}}}>
                <Typography component="img" src="images/matching.png" width="100%"></Typography>
            </Grid>
            <Grid container xs={12} lg={6}>
                <Box sx={{ display: `flex`, flexDirection:`column`, alignItems: `center`,marginTop: 8, width:`100%`}}>
                <Avatar >
                    <LoginRounded sx={{ bgcolor: 'secondary.main'}} />                    
                </Avatar>
                <Typography component="h1" variant="h5">Forgot Password</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt:3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField fullWidth name="email" type="email" required  label="User Email" autoFocus />
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" fullWidth sx={{ mt:2}}>Submit</Button>
                </Box>
            </Box>
            </Grid>
        </Grid>
        <HowItWorks />
        </Container>        
    )
}

export default ForgotPassword;