import { Fragment, useState } from "react"
import ToDoList from "./ToDoList";


export  default function Memo () {
    const [count, setCount] = useState(0);
    const todos = ["todo1", "todo2", "todo3", "todo4"];


    const increment = () =>{
        setCount((c) => c+1 );
    }


    return (
        <Fragment>
            <h1>Home1</h1>
            <p>Count { count }</p>
            <button onClick={increment} >Increment</button>
            <ToDoList todos={todos} />
        </Fragment>
    )

}
