import {  Grid, Container, Typography, Button, } from '@mui/material';
import SearchForm from '../components/Search/SearchForm';

const searchResults = [
    {
        id: 33,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 34,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 35,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 36,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 37,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 38,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 39,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 40,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
    {
        id: 41,
        age: 46,
        gender: 'M',
        name: 'Profile Name',
        image: '/images/1.jpg',
    },
];

const Search = () => {


    return (
        <Container >
        <Grid container xs={12} lg={12} sx={{ marginTop:`75px` }}>
            <Grid item  xs={12} lg={9} pr={2} >
                <Typography component="h1" variant="h4">Search Results</Typography>
                {searchResults.map(result => (
                    <Grid container key={result.id} mb={2} p={1} sx={{border: `1px solid #ccc`}}>
                        <Grid item xs={6} lg={4} sx={{ textAlign:`center`}}>
                            <img  src={result.image} alt="image {result.id}" width="200" /> 
                            <div>Last Login : 3 months ago</div>
                        </Grid>
                        <Grid item xs={6} lg={8}>
                            <Typography component="h1" variant="h6"> {result.name}</Typography>
                            <Typography component="p" >
                                Age: {result.age} Yrs <br />
                                Gender:  {result.gender} <br />
                                Height: 5ft 4 in <br />
                                Martial Status: Unmarried <br />
                                City: Coimbatore<br />
                                Occupation: Software Professional<br />
                                Education : MBA <br />
                                <Button variant="contained" color="primary">View full Profile</Button>
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12} lg={3}>
                <img src="https://www.srivishwakarmasangam.org/assets/images/default_post.jpg" width="100%" />
                <SearchForm />
            </Grid>
        </Grid>
        </Container>

    )

}

export default Search;