
import {createUseStyles} from 'react-jss'


const RegisterStyles = createUseStyles((theme) => ({
    radioOutline: {
        boxShadow: `0px 2px 1px -1px rgba(247, 165, 12,0.2),0px 1px 1px 0px rgba(247, 165, 12, 1),0px 1px 3px 0px rgba(247, 165, 12,1)`,
        borderRadius: `10px`,
        paddingRight: `10px`,
        margin: `5px`,
    },
  }));

export default RegisterStyles;
